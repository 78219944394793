"use client";

import { redirect, useRouter } from "next/navigation";
import { useEffect } from "react";

export default function Home() {
  useEffect(() => {
    const redirectUrl =
      sessionStorage.getItem("redirectUrl") ?? "/event/billboard";
    redirect(redirectUrl);
  }, []);

  return <></>;
}
